import "./Landing.css";
import React from "react";

import { useLocation, useParams } from "react-router-dom";

// Fetch
import {
  getDetails,
  getImagesById,
  getfloatingImagesById,
} from "./fetch/fetchLanding";
import connectConfig from "./config/connectConfig";
import { Helmet } from "react-helmet";
import TagManager from 'react-gtm-module'

const gtmCode = {
  'jintan-bifinasi-youtube_dec2023':'GTM-TSCPD5G',
  'return-hairdye-jan2024':'GTM-TSCPD5G',
  'jintan-bifinasi-youtube_jan2024':'GTM-TSCPD5G',
  'jintan-bifinasi-youtube_mar2024':'GTM-TSCPD5G'
}

export default function Landing() {
  const [landingData, setLandingData] = React.useState({});
  const [landingImages, setLandingImages] = React.useState([]);
  const [floatingImages, setFloatingImages] = React.useState([]);


  const location = useLocation();
  const params = useParams()


  const fetchAll = async () => {
    let brand = "",
      product = "",
      source = "";
      if(gtmCode[params.id]){
        const tagManagerArgs = {
          gtmId: gtmCode[params.id]
        }
        TagManager.initialize(tagManagerArgs)
      }
    
    if (location.pathname) {
      brand = params.id.split("-")[0];
      product = params.id.split("-")[1];
      source = params.id.split("-")[2];

      await getDetails(brand, product, source).then(async (data) => {
        if (data && data.results) {
          let tempArr = [...data.data];
          setLandingData(...data.data);
          if (tempArr.length > 0) {
            // if(tempArr[0].gtm_code){
            //   TagManager.initialize({
            //     gtmId: tempArr[0].gtm_code
            //   })
            // }
            await getImagesById(parseInt(tempArr[0].id)).then((data) => {
              if (data && data.results) {
                setLandingImages(data.data);
              }
            });
            await getfloatingImagesById(parseInt(tempArr[0].id)).then(
              async (data) => {
                if (data && data.results) {
                  setFloatingImages(data.data);
                }
              }
            );
          }
        } else {
          setLandingData({});
        }
      });
    }
  };

  React.useEffect(() => {
    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {landingData.title
            ? landingData.title
            : "3ChemBio - Your Well-Being Expert"}
        </title>
      </Helmet>

      {Object.keys(landingData).length !== 0 && (
        <div className="container">
          <div
            className={
              landingData.bitly3chembio && landingData.bitlymannings
                ? "main"
                : "main-special"
            }
          >
            {landingImages.length > 0 &&
              landingImages.map((data, i) => (
                <img
                  key={i}
                  src={`${connectConfig.s3}/${data.images_name}`}
                  alt="landing"
                  width="100%"
                  draggable="false"
                />
              ))}
          </div>
          {floatingImages.length > 0 && (
            <div className="floatBtn">
              <a
                href={
                  floatingImages.length > 0 && floatingImages[0].bitlyfloating
                }
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`${connectConfig.s3}/${
                    floatingImages.length > 0 && floatingImages[0].images_name
                  }`}
                  alt="floating"
                  width="100%"
                  draggable="false"
                />
              </a>
            </div>
          )}
          {(landingData.bitly3chembio || landingData.bitlymannings) && (
            <div className="bottom">
              <div className="bottomWrapper">
              {landingData.bitly3chembio && (
                <a
                  href={
                    Object.keys(landingData).length !== 0
                      ? `${landingData.bitly3chembio}`
                      : "#"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="sideBtnLeft">
                    <img src="/images/3chembio.gif" alt="logo" />
                  </div>
                </a>
                )}
                {landingData.bitlymannings && (
                <a
                  href={
                    Object.keys(landingData).length !== 0
                      ? `${landingData.bitlymannings}`
                      : "#"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="sideBtnLeft">
                    <img src="/images/mannings.gif" alt="logo" />
                  </div>
                </a>
                )}
              </div>
            </div>
          )}
          
        </div>
      )}
    </>
  );
}
