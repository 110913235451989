import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter,Routes,Route } from "react-router-dom";


import "./index.css";
import Landing from "./Landing";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
  <Routes>
        <Route path="/:id" element={<Landing />}>
        </Route>
      </Routes>
  </BrowserRouter>
);
