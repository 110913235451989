// Connection
import connectConfig from "../config/connectConfig";

export const getDetails = async (brand, product, source) => {
  try {
    const queryRoute = `/landing/getDetails/`;
    const reqBody = {
      brand: brand,
      product: product,
      source: source,
    };

    const fetchRes = await fetch(`${connectConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    return await fetchRes.json();
  } catch (err) {
    console.log(err.message);
    return false;
  }
};

export const getImagesById = async (id) => {
  try {
    const queryRoute = `/landing/getImagesById/${id}`;

    const fetchRes = await fetch(`${connectConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return await fetchRes.json();
  } catch (err) {
    console.log(err.message);
    return false;
  }
};

export const getfloatingImagesById = async (id) => {
  try {
    const queryRoute = `/landing/getfloatingImagesById/${id}`;

    const fetchRes = await fetch(`${connectConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return await fetchRes.json();
  } catch (err) {
    console.log(err.message);
    return false;
  }
};
